<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">a) Identify the Brønsted-Lowry base in the following reaction:</p>

      <p class="pl-14 mb-n2">
        <stemble-latex content="$\ce{HCOOH(aq) + H2O(l) ⇌ HCOO-(aq) + H3O+(aq)}$" />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" class="mb-0" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question510',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{HCOOH(aq)}$', value: 'formic'},
        {text: '$\\ce{H2O(l)}$', value: 'water'},
        {text: '$\\ce{HCOO-(aq)}$', value: 'formate'},
        {text: '$\\ce{H3O+(aq)}$', value: 'hydronium'},
      ],
    };
  },
};
</script>
